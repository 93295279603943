// number of rows on searhc result page
import { dateOffset } from 'lib/utils'

export const ROWS_PER_PAGE = 10

export const THEME = 'light'

export const SORT_RELEVANCE = 'relevance'
export const SORT_DATE = 'date'
export const SORT_CLICKS = 'clicks'
export const SORT_TITLE = 'title'
export const SORT_RANKING = 'ranking'
export const SORT_EXPIRYDATE = 'expiry-date'
export const SORT_DATE_TITLE_DESC = 'date-title-desc'

export const RANGE_2DAYS = { gte: 'now-' + (2 + dateOffset()) + 'd/d', lte: 'now/d' }
export const RANGE_7DAYS = { gte: 'now-' + (7 + dateOffset()) + 'd/d', lte: 'now/d' }
export const RANGE_ALLTIME = ''

export const COUNTRY_AT = 'at'
export const COUNTRY_DE = 'de'

export const AUTOCOMPLETE_MINCHARS = 2

// swiper titles
export const TITLE_TOP20_7DAYS = 'Top 20 der Woche'
export const TITLE_TOP20_2DAYS = 'Trends'
export const TITLE_JUST_ADDED = 'Kürzlich hinzugefügt'
export const TITLE_EXPIRING = 'Nur noch kurze Zeit'

// more shows on show and series page
export const MORE_SHOWS_AMOUNT = 20
