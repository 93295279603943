import differenceInDays from 'date-fns/differenceInDays'
import parse from 'date-fns/parse'
import subDays from 'date-fns/subDays'

export const CDN_STATIONS_BASEURL = '/images/stations'
export const CDN_SHOWS_BASEURL = 'https://sendungen.fra1.cdn.digitaloceanspaces.com'

export function getImageURL (image, station) {
  if (image) {
    return CDN_SHOWS_BASEURL + '/' + image
  } else {
    return CDN_STATIONS_BASEURL + '/' + station + '.png'
  }
}

export function buildHref (path, query, changedFieldValues) {
  if (!query && !changedFieldValues) {
    return path
  }

  const cloned = { ...query }
  for (const [key, value] of Object.entries(changedFieldValues)) {
    if (value) {
      cloned[key] = value
    } else {
      delete cloned[key]
    }
  }

  let result = path + '?'
  Object.keys(cloned).forEach(function (key) {
    const val = cloned[key]
    result += key + '=' + val + '&'
  })
  if (right(result, 1) === '&') result = left(result, result.length - 1)
  if (right(result, 1) === '?') result = left(result, result.length - 1)

  return result
}

export function left (string, chars) {
  return string.substr(0, chars)
}

export function right (string, chars) {
  return string.substr(string.length - chars)
}

export function caching (response, seconds) {
  if (seconds > 0) {
    response.setHeader('cache-control', `s-maxage=${seconds}, stale-while-revalidate`)
  }
}

export function getCountry () {
  return process.env.NEXT_PUBLIC_COUNTRY
}

export function hostWithPort () {
  const url = new URL(process.env.BASEURL)
  let host = url.host
  if (host.slice(-1) === '/') {
    host = host.slice(1, host.length)
  }
  return host
}

export function today () {
  if (process.env.NEXT_PUBLIC_IMPORT_DATE) {
    return parse(process.env.NEXT_PUBLIC_IMPORT_DATE, 'dd.MM.yyyy', new Date(2000, 0, 1))
  } else {
    return new Date()
  }
}

export function dateOffset () {
  if (process.env.NEXT_PUBLIC_IMPORT_DATE) {
    return differenceInDays(new Date(), today())
  } else {
    return 0
  }
}

// Criteria:
// 1. Show has been running on the primary station in the last 7 days and is not a trailer
// 2. Show has been running on another station in the last 7 days and is not a trailer
// 3. Trailer has been running on the primary station in the last 7 days
// 4. Trailer has been running on another station in the last 7 days
// 5. Shows has been running on the primary station and is not a trailer
// 6. Show is not a trailer
// 7. Show has been running on the primary station
export function findLatestVideoForSeries (series, videos) {
  let primaryStation = 'undefined'
  if (series.stations[0] && series.stations[0].name) {
    primaryStation = series.stations[0].name
  }

  for (const video of videos) {
    const data = video._source
    data.points = 0

    if (parseDate(data.date) >= subDays(today(), 7)) data.points += 100
    if (data.trailer === false) data.points += 10
    if (data.stations[0].name === primaryStation) data.points += 1
  }

  const sorted = [...videos].sort((a, b) => {
    if (a._source.points !== b._source.points) {
      return b._source.points - a._source.points
    } else if (parseDate(a._source.date) > parseDate(b._source.date)) {
      return -1
    } else if (parseDate(a._source.date) < parseDate(b._source.date)) {
      return 1
    } else {
      return 0
    }
  })
  return sorted[0]
}

function parseDate (dateAsString) {
  return parse(dateAsString, 'dd.MM.yyyy', new Date(2000, 0, 1))
}

export function testSupportsSmoothScroll () {
  let supports = false
  try {
    const div = document.createElement('div')
    div.scrollTo({
      top: 0,
      get behavior () {
        supports = true
        return 'smooth'
      }
    })
  } catch (err) {}
  return supports
}

export function getTimezoneOffset (tz) {
  const now = new Date()
  const a = now.toLocaleString('ja', { timeZone: tz }).split(/[/\s:]/)
  a[1]--
  const t1 = Date.UTC.apply(null, a)
  const t2 = new Date(now).setMilliseconds(0)
  return (t2 - t1) / 60 / 1000
}

export function sortByRemainingHours (shows) {
  const sorted = shows.sort((a, b) => a._source.remainingHours - b._source.remainingHours)
  return sorted
}

export function isValidDate (d) {
  return d instanceof Date && !isNaN(d)
}

export function replaceUmlauts (string) {
  let value = string.toLowerCase()
  value = value.replace(/ä/g, 'ae')
  value = value.replace(/ö/g, 'oe')
  value = value.replace(/ü/g, 'ue')
  return value
}
