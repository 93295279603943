import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles((theme) => ({
  '@global': {
    ':root': {
      '--swiper-navigation-size': '48px'
    },
    '.swiper-button-prev, .swiper-button-next': {
      height: '48px !important',
      width: '48px !important',
      marginTop: 'calc(-1 * (var(--swiper-navigation-size)/ 2 + 2px))',
      color: 'white !important',
      backgroundColor: alpha(theme.palette.background.default, 0.2),
      '@media (max-width: 960px)': {
        display: 'none'
      }
    },
    '.swiper-button-prev': {
      left: '0 !important'
    },
    '.swiper-button-next': {
      right: '0  !important'
    },
    '.swiper-button-prev:hover, .swiper-button-next:hover': {
      backgroundColor: theme.palette.primary.main + ' !important'
    },
    '.swiper-button-disabled': {
      display: 'none  !important'
    },
    '.swiper-button-next:after, .swiper-button-prev:after': {
      fontSize: '24px !important',
      fontWeight: 'bold !important'
    },
    '.swiper-container-horizontal > .swiper-pagination': {
      position: 'absolute',
      bottom: 0
    },
    '.row-series .swiper-button-prev, .row-series .swiper-button-next': {
      marginTop: 0,
      top: '33px'
    },
    '.row-videos .swiper-button-prev, .row-videos .swiper-button-next': {
      marginTop: 0,
      top: '116px'
    },
    '.swiper-pagination-bullet-active': {
      backgroundColor: theme.palette.primary.main + ' !important'
    },
    '.swiper-lazy-preloader-white': {
      top: '60px'
    },
    '#nprogress .bar, #nprogress .spinner': {
      zIndex: 10000
    },
    '#nprogress .bar': {
      backgroundColor: theme.palette.primary.main
    },
    '#nprogress .spinner': {
      display: 'flex',
      justifyContent: 'center',
      position: 'fixed',
      top: '18px',
      left: 0,
      right: 0
    },
    '#nprogress .spinner-icon': {
      borderTopColor: theme.palette.primary.main,
      borderLeftColor: theme.palette.primary.main
    },
    /* IE11 styles */
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      /* swiper for ie11, see: https://github.com/nolimits4web/swiper/issues/3585 */
      '.swiper-button-next': {
        backgroundImage: 'url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D\'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\'%20viewBox%3D\'0%200%2027%2044\'%3E%3Cpath%20d%3D\'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z\'%20fill%3D\'%23ffffff\'%2F%3E%3C%2Fsvg%3E")'
      },
      '.swiper-button-prev': {
        backgroundImage: 'url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D\'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\'%20viewBox%3D\'0%200%2027%2044\'%3E%3Cpath%20d%3D\'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z\'%20fill%3D\'%23ffffff\'%2F%3E%3C%2Fsvg%3E")'
      }
    }
  }
}))

export default function GlobalCss () {
  useStyles()

  return (
    <div className='cssjss-advanced-global-root'>
      <div className='cssjss-advanced-global-child' />
    </div>
  )
}
