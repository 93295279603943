export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (eventName, params) => {
  if (GA_TRACKING_ID) {
    window.gtag('event', eventName,
      params
    )
  }
}
